











































































































import { SortOrder } from '@/jbi-shared/types/search.types';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import { State } from 'vuex-class';
import BasePaginatorHoc from '../../../../components/base/BasePaginatorHoc.vue';
import {
  FilteredGetGroup,
  Group,
  GroupType,
  Pagination
} from '@/store/modules/admin/types/admin.types';
import { RootState } from '@/store/store';
import SelectGroupList from './select-group-list/SelectGroupList.vue';
import {
  getFilteredGroups,
  getFilteredGroupsWithAncestorsAndSubgroups,
  getPaginatedFilteredGroups,
  getTotalCount
} from '@/utils/group.util';

@Component({
  components: {
    BasePaginatorHoc
  }
})
export default class SelectGroupDropdown extends Vue {
  @Prop(String) public label!: string;
  @Prop(String) public subtitle!: string;
  @Prop(Array) public selectedGroups!: Group[];
  @Prop(Array) public defaultSelectedGroupIds!: number[];
  @Prop({ type: Boolean, default: false })
  public displayCheckbox!: boolean;
  @Prop({ type: Boolean, default: false })
  public checkboxValue!: boolean;
  @Prop(String) public checkboxLabel!: string;
  @Prop(Array) public groupStored!: Group[];

  public perPage: number = 10;
  public page: number = 1;
  public sortColumn: string = 'name';
  public sortOrder: SortOrder = SortOrder.ASC;
  public filterParams: FilteredGetGroup = { ...this.initialState };
  public lastFilterParams: FilteredGetGroup = { ...this.initialState };
  public expandGroupFilter: boolean = true;
  public selectedGroupIds: number[] = [];
  public totalCount: number = 0;
  public groupIds: string[] = [];
  public groupPaths: string[] = [];

  public originalGroups: Group[] = [];
  public filteredGroups: Group[] = [];
  public subGroups: Group[] = [];

  public currentSelectedGroup: Group[] = [];

  @State(({ admin }: RootState) => admin.groupTypes)
  public groupTypes!: GroupType[] | Pagination<GroupType>;

  get typeOptions():
    | Array<{
        id: number;
        name: string;
        slug: string;
      }>
    | undefined {
    if (this.groupTypes) {
      let groupTypes: GroupType[] | Pagination<GroupType> = [];

      if ('items' in this.groupTypes) {
        groupTypes = this.groupTypes.items;
      } else {
        groupTypes = this.groupTypes;
      }

      const types = Object.values(groupTypes).map((groupType: GroupType) => ({
        id: groupType.id,
        slug: groupType.name,
        name: groupType.name
      }));
      return [{ id: 0, name: 'All', slug: '' }, ...types];
    }
  }

  get SelectGroupList(): typeof SelectGroupList {
    return SelectGroupList;
  }

  get initialState(): {
    groupname: string;
    grouptype: string;
  } {
    return {
      groupname: '',
      grouptype: ''
    };
  }

  public updateSelectedGroup(): void {
    this.$emit('updateSelectedGroup', this.selectedGroupIds);
    this.closeModal();
  }

  public handleSort(data: { sortColumn: string; sortOrder: SortOrder }): void {
    this.sortColumn = data.sortColumn;
    this.sortOrder = data.sortOrder;

    const parentGroups: Group[] = this.originalGroups.filter(
      (group) => group.nlevel === 1
    );
    const subGroups: Group[] = this.originalGroups.filter(
      (group) => group.nlevel !== 1
    );

    switch (this.sortColumn) {
      case 'name':
        if (this.sortOrder === 'ASC') {
          this.originalGroups = [
            ...parentGroups.sort((a, b) => ('' + a.name).localeCompare(b.name)),
            ...subGroups
          ];
        } else {
          this.originalGroups = [
            ...parentGroups.sort((a, b) => ('' + b.name).localeCompare(a.name)),
            ...subGroups
          ];
        }
        break;
      case 'group_type':
        if (this.sortOrder === 'ASC') {
          this.originalGroups = [
            ...parentGroups.sort((a, b) =>
              ('' + a.types.name).localeCompare(b.types.name)
            ),
            ...subGroups
          ];
        } else {
          this.originalGroups = [
            ...parentGroups.sort((a, b) =>
              ('' + b.types.name).localeCompare(a.types.name)
            ),
            ...subGroups
          ];
        }
        break;
      default:
        break;
    }
    this.handlePaginator({ perPage: this.perPage, page: this.page });
  }

  public async handlePaginator({
    perPage,
    page
  }: {
    perPage: number;
    page: number;
  }) {
    this.page = page;
    this.expandGroupFilter = true;

    const combinedGroups = getFilteredGroupsWithAncestorsAndSubgroups(
      this.originalGroups,
      this.filterParams
    );

    this.filteredGroups = getPaginatedFilteredGroups(
      combinedGroups,
      perPage,
      page
    );

    this.totalCount = getTotalCount(
      combinedGroups,
      this.originalGroups,
      this.filterParams
    );

    if (
      this.filterParams.groupname?.length === 0 &&
      this.selectedGroupIds.length === 0
    ) {
      this.expandGroupFilter = false;
    }
  }

  public filterGroupByAncestors(groups: Group[], selectedGroups?: Group[]) {
    let groupWithAncestors: Group[] = [];
    (selectedGroups || this.selectedGroups).forEach((group) => {
      const ancestorPath: string =
        group.id === +group.path ? '' : group.path.split('' + group.id)[0];
      groupWithAncestors = [
        ...groupWithAncestors,
        ...groups
          // Avoid path like 550.551 matched with 5
          .filter((item) => (item.path + '.').startsWith(group.path + '.'))
          .map((item) => ({
            ...item,
            path:
              ancestorPath === '' ? item.path : item.path.split(ancestorPath)[1]
          }))
          .map((item) => ({ ...item, nlevel: item.path.split('.').length }))
      ];
    });
    return this.getUniqGroups(groupWithAncestors);
  }

  public onTriggerDropdown(
    isOpen: boolean,
    sortColumn: string,
    sortOrder: SortOrder
  ) {
    if (isOpen) {
      this.filterParams = {
        grouptype: '',
        groupname: ''
      };
    }
    this.handleSort({ sortColumn, sortOrder });
  }

  public getUniqGroups(groups: Group[]): Group[] {
    return [...new Map(groups.map((group) => [group.id, group])).values()];
  }

  public closeModal(): void {
    this.$emit('close');
  }

  public onCheckboxChange(value: boolean): void {
    this.$emit('onCheckboxChange', value);
  }

  @Watch('selectedGroupIds')
  public onSelectedGroupIdsChange(): void {
    this.currentSelectedGroup = this.selectedGroupIds.map((id) =>
      this.originalGroups.find((group) => group.id === id)
    ) as Group[];
    this.$emit('selectedGroup', this.selectedGroupIds);
  }

  @Watch('defaultSelectedGroupIds')
  public onDefaultSelectedGroupIdsChange(): void {
    this.selectedGroupIds = this.defaultSelectedGroupIds;
  }

  @Watch('groupStored')
  @Watch('selectedGroups')
  public onSelectedGroupChange(): void {
    if (this.groupStored) {
      this.originalGroups = this.filterGroupByAncestors(this.groupStored);
    }
    this.selectedGroupIds =
      this.defaultSelectedGroupIds?.length > 0
        ? this.defaultSelectedGroupIds
        : [];
    this.handlePaginator({ perPage: this.perPage, page: this.page });
    this.totalCount = this.originalGroups.filter(
      (group) => group.nlevel === 1
    ).length;
  }

  @Watch('filterParams', { deep: true })
  public onChange(): void {
    this.handlePaginator({ perPage: this.perPage, page: this.page });
  }
}
